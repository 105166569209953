@import '../../variables.scss';

.sidebar {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: space-between;
    width: 180px;
    height: 100vh;
    background-color: #FFFFFF;
    border-right: 1px solid #E0E2E7;
    padding: 64px 18px 24px;

    .group {
        display: flex;
        flex-direction: column;
        gap: 8px;

        a {
            display: flex;
            align-items: center;
            gap: 11px;
            width: 100%;
            background-color: transparent;
            border-radius: 8px;
            padding: 8px 12px;
            font-size: 14px;
            font-weight: 600;
            color: $primary-color-font;
            transition: all .3s ease-in-out;
            text-decoration: none;

            svg {
                path {
                    transition: fill .3s ease-in-out;
                }
            }

            &:hover,
            &[aria-current="page"] {
                background-color: $primary-color-font;
                color: $secondary-color-font;

                svg {
                    path {
                        fill: $secondary-color-font;
                    }
                }
            }
        }
    }
}