.Home {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .top-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    gap:10px;
  }
  
  .card {
    flex: 1;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    /* margin: 0 10px; */
    border:solid 1.5px #e0e2e7;
    
  }
  
  .increase {
    color: green;
  }
  
  .decrease {
    color: red;
  }
  .filter-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .filter-buttons button {
    margin: 0 5px;
    padding: 5px 10px;
    background-color: #14213d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .no-change {
    color: gray;
  }
  
  .bottom-section {
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;

  }
  
  .sales-progress {
    flex: 1;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border:solid 1.5px #e0e2e7;
  }
  
  .statistics {
    flex: 2;
    background: #ffffff;
    padding: 20px;
    border-radius: 8px;
    border:solid 1.5px #e0e2e7;
  }
  
  .progress {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .progress-circle {
    position: relative;
    width: 100px;
    height: 100px;
  }
  
  .progress-circle svg {
    transform: rotate(-90deg);
    width: 100px;
    height: 100px;
  }
  
  .progress-circle circle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 10;
    stroke: #e0e0e0;
    r: 50;
    cx: 50;
    cy: 50;
  }
  
  .progress-circle circle:nth-child(2) {
    stroke: #3b82f6;
    stroke-dasharray: 314;
    stroke-dashoffset: 314;
    transition: stroke-dashoffset 1s;
  }
  
  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
  
  .targets {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 10px;
  }
  