.drag-drop-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 11px;

    .drag-drop {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        min-height: 313px;
        background-color: #f9f9fc;
        border: 1px dashed #e0e2e7;
        border-radius: 8px;
        padding: 24px 12px;

        .hidden {
            display: none;
        }

        svg {
            display: block;
            max-width: 100%;
            height: auto;
            margin: 0 auto;
        }

        p {
            font-size: 16px;
            font-weight: 500;
            color: #292d32;
            text-align: center;
            margin: 13px 0 21px;
        }

        button {
            display: block;
            max-width: 100%;
            min-height: 34px;
            background-color: #17223c;
            border: none;
            border-radius: 7px;
            outline: none !important;
            font-size: 12px;
            font-weight: 400;
            color: #fcf4e6;
            text-align: center;
            transition: background-color 0.3s ease-in-out;
            margin: 0 auto;
            padding: 10px 14px;

            &:hover {
                cursor: pointer;
                background-color: #0c84f3;
            }
        }

        &.drag-drop-active {
            background-color: rgba(192, 192, 192, 0.904);
        }
    }

    .images-preview {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 8px;

        .image-container {
            img {
                display: block;
                max-width: 100%;
                height: auto;
                margin: 0 auto 14px;
            }
        }

        .pdf-container {
            display: flex;
            max-width: 100%;
            height: auto;
            overflow: hidden;

            .react-pdf {
                overflow: hidden;
                &__Page {
                    overflow: hidden;

                    &__canvas {
                        background-color: rgba(192, 192, 192, 0.904);
                        max-width: 270px !important;
                        height: 100% !important;
                    }
                }
            }
        }
    }
}
