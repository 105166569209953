@import "../../../variables.scss";

.crear-editar-desarrollo {
    padding: 0 24px 32px;
}

.modal_load_from_crm {
    min-width: 800px;
    max-width: 800px;
    height: fit-content;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 16px;
    margin: 25vh 0;

    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    p {
        padding: 10px;
        border: 1px solid black;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
            background-color: $primary-color-font;
            color: $secondary-color-font;
        }
    }
}
