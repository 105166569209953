.container {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    z-index: 9999;
    width: 100vw;
    height: 100vh;

    top: 0;
    left: 0;

    background-color: rgba($color: #17223c, $alpha: 0.5);

    pointer-events: none;
    overflow-y: auto;

    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    &.opened {
        opacity: 1;
        pointer-events: initial;
    }
}
