@import "../../variables.scss";

.container {
    height: 150px;
    width: 270px;
    border: 1px solid #e0e2e7;
    border-radius: 8px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

    svg {
        position: absolute;
        left: 230px;
        top: 5px;
        cursor: pointer;
        background-color: $primary-color-font;
        border-radius: 20px;
        padding: 5px;
        transform: scale(0.8);
        transition: ease-in-out 0.1s;

        &:hover {
            transform: scale(1);
        }
    }

    img {
        object-fit: fill;
        border-radius: 8px;
        margin: 0px;
    }
}

.container_add {
    height: 150px;
    width: 270px;
    border: 1px solid #e0e2e7;
    border-radius: 8px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
