@import './variables.scss';

form {
    width: 100%;

    label {
        display: block;
        font-size: 14px;
        font-weight: 500;
        color: #4D5464;
        margin-bottom: 8px;
    }

    input:not([type='checkbox']):not([type='radio']),
    select {
        display: block;
        width: 100%;
        background-color: #F9F9FC;
        border: 1px solid #E0E2E7;
        border-radius: 8px;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        color: $primary-color-font;
        outline: none !important;
        transition: border-color .3s ease-in-out;

        &::placeholder {
            color: #858D9D;
        }

        &:focus {
            border-color: $primary-color-font;
        }
    }

    textarea {
        display: block;
        width: 100%;
        height: 200px;
        background-color: #F9F9FC;
        border: 1px solid #E0E2E7;
        border-radius: 8px;
        padding: 10px 12px;
        font-size: 14px;
        font-weight: 400;
        color: $primary-color-font;
        outline: none !important;
        transition: border-color .3s ease-in-out;
        resize: none;

        &::placeholder {
            color: #858D9D;
        }

        &:focus {
            border-color: $primary-color-font;
        }
    }

    input[type="checkbox"] {
        display: block;
        width: 16px;
        height: 16px;
        appearance: none;
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 1px solid $primary-color-font;
            border-radius: 4px;
        }

        &::after {
            position: absolute;
            top: 57%;
            left: 60%;
            transform: translate(-50%, -50%);
            display: block;
            content: "";
            width: 75%;
            height: 75%;
            background-color: transparent;
            border-radius: 4px;
            transition: background-color .3s ease-in-out;
        }

        &:hover {
            cursor: pointer;
        }

        &:checked {
            &::after {
                background-color: $primary-color-font;
            }
        }
    }

    .input-label {
        display: block;
        width: 100%;
        margin-bottom: 14px;
    }

    .checkbox-radio-label {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 6px;

        label {
            min-width: 70px;
            margin-bottom: 0;
        }
    }

    .form-holder {
        display: flex;
        align-items: flex-start;
        gap: 24px;

        .form-col {
            flex: 1 0 0;

            &:last-of-type {
                flex: 3 0 0;
            }
        }
    }

    .card-input {
        display: block;
        height: 100%;
        background-color: #FFFFFF;
        border: 1px solid #E0E2E7;
        border-radius: 8px;
        padding: 24px;
        margin-bottom: 14px;

        h4 {
            font-size: 18px;
            font-weight: 500;
            color: $primary-color-font;
            margin-bottom: 14px;
        }

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}