@import "../../variables.scss";

.user-details-container {
    display: flex;
    padding: 20px;
    font-family: "Inter", sans-serif;
    background-color: #f7f8fc;
}

.user-info {
    width: 30%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-right: 20px;

    display: flex;
    flex-direction: column;
}

.user-avatar {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.verificado {
    background-color: #e6f7e6;
    color: #28a745;
    border-radius: 15px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    margin: 8px auto;
}

.sin-verificar {
    background-color: #fdf1e8;
    color: #e41150;
    border-radius: 15px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    margin: 8px auto;
}

.error-verificacion {
    background-color: #f9bdbd;
    color: #f7f8fc;
    border-radius: 15px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    margin: 8px auto;
}

.verificando {
    background-color: #fdf1e8;
    color: #ff8800;
    border-radius: 15px;
    padding: 5px 10px;
    display: inline-block;
    font-size: 12px;
    margin: 8px auto;
}

.user-info-details {
    text-align: left;
    margin-top: 20px;
}

.info-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
}

.info-item i {
    margin-right: 10px;
    font-size: 18px;
    color: #888;
}

.info-text {
    margin-left: 10px;
}

.bidding-history {
    width: 70%;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bidding-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.period-button {
    background-color: #14213d;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
}

.bidding-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.bidding-table th,
.bidding-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.bidding-table th {
    background-color: #f4f4f4;
}

.additional-products {
    color: #888;
    font-size: 12px;
    display: block;
}

.status {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    display: inline-block;
}

.processing {
    background-color: #f0ad4e;
}

.shipped {
    background-color: #5bc0de;
}

.delivered {
    background-color: #5cb85c;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination button {
    background-color: #14213d;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #14213d;
}
