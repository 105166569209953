// Colors

$mainColor: #FCF4E6;
$beige: #d6d6d6;
$orange: #f5953b;
$sky-blue: #0c84f3;
$red-dark: #701d22;
$blue-dark: #003a70;

$orange-hover: #dd6028;

$primary-color-font: #17223c;
$secondary-color-font: #fcf4e6;
