@import "../../variables.scss";

.container {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &__nav {
        text-align: center;

        span {
            margin-top: 20px;

            display: flex;
            gap: 20px;

            button {
                padding: 7px;
                border: none;
                font-size: 17px;
                border-radius: 7px;
                color: $secondary-color-font;
                background-color: $primary-color-font;
            }
        }
    }
}
