@import "../../variables.scss";

.tareas {
    padding: 0 24px 32px;

    .list-container {
        border-radius: 18px;
        overflow: hidden;
        border: 1px solid #e0e2e7;

        &__filter {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 14px;

            margin-left: 10px;
        }
    }

    .tareas-list {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
            height: 56px;
            background-color: #ffffff;

            th {
                font-size: 14px;
                font-weight: 500;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                padding: 8px;
            }
        }

        tbody {
            height: 80px;

            td {
                font-size: 14px;
                font-weight: 400;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                text-align: center;
                padding: 8px;

                span {
                    display: block;
                    padding: 8px 4px;
                    border-radius: 100px;

                    &.pendiente {
                        background-color: #fdf1e8;
                        color: #e41150;
                    }

                    &.proceso {
                        color: #ff8800;
                        background-color: #fdf1e8;
                    }

                    &.completada {
                        background-color: #e7f4ee;
                        color: #0d894f;
                    }
                }

                .btn-holder {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                }

                button {
                    border: none;
                    outline: none !important;
                    background-color: $primary-color-font;
                    text-decoration: none;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: $sky-blue;
                    }
                }

                a {
                    color: $primary-color-font;
                    text-decoration: none;
                }
            }

            &:nth-of-type(even) {
                background-color: #f7f7f7;
            }
        }
    }
}

.popup-details {
    width: 100%;
    max-width: 800px;
    height: fit-content;
    padding: 32px;
    background-color: #ffffff;
    border-radius: 16px;
    margin: 25vh 0;

    .actions-popup-task {
        margin-top: 20px;
        padding: 10px 10px;
        border: 2px solid #1a2d59;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        span {
            display: flex;
            gap: 10px;
        }

        &__button {
            border: none;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;

            &:hover {
                position: relative;
                transform: translateY(2px);
                box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
            }
        }
    }

    &.popup-active {
        opacity: 1;
        pointer-events: initial;
    }

    .close-btn {
        display: block;
        width: fit-content;
        height: fit-content;
        background: none;
        border: none;
        outline: none !important;
        margin-left: auto;
        transition: opacity 0.3s ease-in-out;

        &:hover {
            cursor: pointer;
            opacity: 0.75;
        }
    }

    h2 {
        font-size: 32px;
        font-weight: 700;
        color: $primary-color-font;
        margin: 24px 0;
    }

    p {
        font-size: 16px;
        font-weight: 400;
        color: $primary-color-font;
    }

    .change-state-btn {
        display: block;
        border: none;
        outline: none !important;
        background-color: $primary-color-font;
        text-decoration: none;
        border-radius: 8px;
        padding: 14px;
        font-size: 14px;
        font-weight: 600;
        color: $secondary-color-font;
        transition: background-color 0.3s ease-in-out;
        margin: 24px 0 0 auto;

        &:hover {
            cursor: pointer;
            background-color: $sky-blue;
        }
    }
}
