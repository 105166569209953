@import "../../../variables.scss";

.desarrollo {
    padding: 0 24px 32px;

    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin-bottom: 32px;
    }

    h2 {
        font-size: 32px;
        font-weight: 700;
        color: $primary-color-font;
        margin: 32px 0;
    }

    .list-container {
        border-radius: 18px;
        overflow: hidden;
        border: 1px solid #e0e2e7;
    }

    .desarrollos-list {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
            height: 56px;
            background-color: #ffffff;

            th {
                font-size: 14px;
                font-weight: 500;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                padding: 8px;
            }
        }

        tbody {
            height: 80px;

            td {
                font-size: 14px;
                font-weight: 400;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                text-align: center;
                padding: 8px;

                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin: 0 auto;
                }

                .btn-holder {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                }

                button {
                    background-color: $red-dark;
                    border: none;
                    outline: none !important;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: #8f1d25;
                    }
                }

                a {
                    background-color: $primary-color-font;
                    text-decoration: none;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        background-color: $sky-blue;
                    }

                    // &:last-of-type {
                    //     background-color: #FFFFFF;
                    //     color: $primary-color-font;
                    //     border: 1px solid $blue-dark;

                    //     &:hover {
                    //         background-color: $blue-dark;
                    //         color: #FFFFFF;
                    //     }
                    // }
                }
            }

            &:nth-of-type(even) {
                background-color: #f7f7f7;
            }
        }
    }
}

.section-body-developments {
    display: flex;
    flex-direction: column;

    gap: 20px;

    &__banner {
        min-height: 150px;
        margin-bottom: 10px;

        &__content {
            display: flex;
            align-items: flex-start;
            gap: 10px;

            img {
                border-radius: 20px;
            }
        }
    }

    &__gallery {
        min-height: 150px;
        margin-bottom: 10px;

        &__content {
            margin-top: 10px;

            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: left;
            align-items: center;

            &__button_add {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #e0e2e7;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

                font-size: 40px;
                width: 50px;
                height: 50px;
                border-radius: 120px;
                cursor: pointer;
            }
        }

        span {
            height: 150px;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.edit-lots-development {
    width: 88vw;
    height: auto;
}

.leyendas-desarrollo {
    display: flex;
    gap: 30px;
    margin-bottom: 10px;
    font-size: 14px;

    &__disponibles {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
            width: 20px;
            height: 20px;
            background-color: #b8d368;
            border-radius: 50%;
        }
    }

    &__subastas_activas {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
            width: 20px;
            height: 20px;
            background-color: #023e7d;
            border-radius: 50%;
        }
    }

    &__vendidos {
        display: flex;
        align-items: center;
        gap: 10px;

        div {
            width: 20px;
            height: 20px;
            background-color: #fc7032;
            border-radius: 50%;
        }
    }
}

.container-add-image-popup {
    max-width: 80vw;
    max-height: 95vh;

    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .drag-drop {
        min-width: 350px;
        width: auto;
    }

    img {
        height: 150px !important;
        width: auto;
    }
}

.container-add-documentation-popup {
    max-width: 80vw;
    max-height: 95vh;

    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .drag-drop {
        min-width: 350px;
        width: auto;
        max-width: 500px;
        max-height: 500px;
    }

    img {
        height: 150px !important;
        width: auto;
    }
}

.container-add-image-button {
    margin-left: 15px;

    width: 200px;
    height: 30px;

    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 7px;

    &:hover {
        background-color: $primary-color-font;
        color: $secondary-color-font;
    }
}

.edit-pdf-documentation {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    height: auto;

    &__header {
        display: flex;
        align-items: center;

        gap: 15px;
        width: 100%;

        margin-bottom: 10px;

        span {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;

            background-color: $primary-color-font;
            color: $secondary-color-font;
            padding: 5px;
            border-radius: 7px;

            p {
                font-size: 13px;
            }
        }
    }
}

.section-header-logo {
    display: flex;
    align-items: flex-start;
}

.change-logo-popup {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;

    background-color: $primary-color-font;
    color: $secondary-color-font;
    padding: 5px;
    border-radius: 7px;

    p {
        font-size: 13px;
    }
}
