@import "../../variables.scss";

.desarrollos {
    padding: 0 24px 32px;

    .list-container {
        border-radius: 18px;
        overflow: hidden;
        border: 1px solid #e0e2e7;
    }

    h2 {
        margin: 20px 0px 10px 0px;
    }

    .desarrollos-list {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
            height: 56px;
            background-color: #ffffff;

            th {
                font-size: 14px;
                font-weight: 500;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                padding: 8px;
            }
        }

        tbody {
            height: 80px;

            td {
                font-size: 14px;
                font-weight: 400;
                color: $primary-color-font;
                border: 1px solid #e0e2e7;
                text-align: center;
                padding: 8px;

                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                    margin: 0 auto;
                }

                .btn-holder {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                }

                a {
                    background-color: $primary-color-font;
                    text-decoration: none;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color 0.3s ease-in-out;

                    &:hover {
                        background-color: $sky-blue;
                    }

                    &:last-of-type {
                        background-color: #ffffff;
                        color: $primary-color-font;
                        border: 1px solid $blue-dark;

                        &:hover {
                            background-color: $blue-dark;
                            color: #ffffff;
                        }
                    }
                }
            }

            &:nth-of-type(even) {
                background-color: #f7f7f7;
            }
        }
    }
}

.btn-activate {
    background-color: green !important;
}
