@import "../../variables.scss";

.popupLot {
    position: absolute;
    z-index: 999;

    top: 10px;
    left: 75%;

    width: auto;
    height: auto;

    border-radius: 10px;
    border: 2px solid $primary-color-font;
    overflow: hidden;

    padding: 10px 15px 20px 10px;

    background-color: $mainColor;
    cursor: default;

    &__header {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px;

        svg {
            cursor: pointer;
        }
    }

    &__body {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        &__field {
            input {
                width: 100%;
                border-radius: 5px;
                border: 1px solid $primary-color-font;
                padding-left: 5px;
                height: 25px;
            }

            select {
                width: 100%;
                height: 25px;
                border-radius: 5px;
                border: 1px solid $primary-color-font;
                padding-left: 5px;
            }
        }

        &__actions {
            display: flex;

            button {
                width: 100%;
                height: 25px;
                margin-top: 10px;
                background-color: $blue-dark;
                color: $secondary-color-font;
                border: 1px solid $secondary-color-font;
                border-radius: 5px;
                cursor: pointer;

                &:hover {
                    background-color: $primary-color-font;
                }
            }
        }
    }
}

.button {
    padding: 10px;
    width: 100px;
    margin: 10px 0px 10px 0px;
    background-color: $primary-color-font;
    color: $secondary-color-font;
    border: 1px solid $secondary-color-font;
    border-radius: 5px;
    cursor: pointer;
}
