.sales-progress-container {
    width: -webkit-fill-available;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40%;
    @media only screen and (min-width: 0px) and (max-width:1100px){
      max-width: 100%;
    }
  }
  
  .sales-progress-container h2,
  .sales-progress-container p {
    margin: 0;
    padding: 5px;
  }
  
  .percentage1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: -webkit-fill-available;
  }
  
  .percentage1 h3 {
    margin: 0;

  }
  
  .increase {
    color: green;
  }
  
  .targets {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .targets div {
    text-align: center;
  }
  
  .targets div p {
    margin: 5px 0;
  }
  