@import "./variables.scss";

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    max-width: 100vw;
    height: 100vh;
    scroll-behavior: smooth;
    overflow: hidden;
    background-color: $mainColor;
    user-select: none;
}

#root {
    display: flex;
    align-items: flex-start;
    gap: 0px;
    background-color: #f9f9fc;
}
.swal2-popup {
    font-family: "Inter", sans-serif;
}

.swal2-confirm {
    background-color: #14213d !important;
}

.swal2-cancel {
    background-color: #bbb !important;
}

.dashboard {
    display: flex;
    width: 100%;
    min-height: 100vh;
    max-height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
li,
th,
td,
input,
select,
textarea,
label {
    font-family: "Inter", sans-serif;
}

h1 {
    font-size: 42px;
    font-weight: 700;
    color: $primary-color-font;
    margin-bottom: 8px;

    @media (max-width: 1024px) {
        text-align: center;
    }

    @media (max-width: 700px) {
        font-size: 32px;
    }
}

.section-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 32px;

    .navigate-page {
        display: flex;
        align-items: center;
        gap: 14px;

        a {
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            color: $primary-color-font;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: $sky-blue;
            }
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: #667085;
        }
    }

    .actions-page {
        display: flex;
        gap: 16px;

        .main-btn {
            border: none;
            outline: none !important;
            display: flex;
            align-items: center;
            gap: 8px;
            background-color: $primary-color-font;
            border-radius: 8px;
            padding: 14px 14px 14px 18px;
            font-size: 14px;
            font-weight: 600;
            color: $secondary-color-font;
            transition: background-color 0.3s ease-in-out;
            text-decoration: none;

            &:hover {
                cursor: pointer;
                background-color: $sky-blue;
            }
        }
    }
}

.leaflet-control-attribution {
    opacity: 0;
}

.ql-toolbar {
    border-radius: 8px !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    background-color: #f9f9fc;
}

.ql-container {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    background-color: #f9f9fc;
    min-height: 200px;
}

.leaflet-editing-icon {
    width: 10px !important;
    height: 10px !important;
    border-radius: 20px;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    max-width: 80vw !important;
    height: 100% !important;
}
