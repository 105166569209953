@import '../../variables.scss';

.subastas {
    padding: 0 24px 32px;

    .list-container {
        border-radius: 18px;
        overflow: hidden;
        border: 1px solid #E0E2E7;
    }

    .desarrollos-list {
        width: 100%;
        border-spacing: 0;
        border-collapse: collapse;

        thead {
            height: 56px;
            background-color: #FFFFFF;

            th {
                font-size: 14px;
                font-weight: 500;
                color: $primary-color-font;
                border: 1px solid #E0E2E7;
                padding: 8px;
            }
        }

        tbody {
            height: 80px;

            td {
                font-size: 14px;
                font-weight: 400;
                color: $primary-color-font;
                border: 1px solid #E0E2E7;
                text-align: center;
                padding: 8px;

                span {
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    margin: 0 auto;
                    padding: 4px 12px;
                    border-radius: 100px;

                    &.no-iniciado {
                        background-color: #FDF1E8;
                        color: #E46A11;
                    }

                    &.abierta {
                        background-color: #E7F4EE;
                        color: #0D894F;
                    }

                    &.cerrada {
                        background-color: #DF6464;
                        color: #FFFFFF;
                    }

                    &.sin-lotes {
                        background-color: #FEEDEC;
                        color: #F04438;
                    }

                    &.en-pausa {
                        background-color: #F0F1F3;
                        color: #667085;
                    }
                }

                .btn-holder {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 6px;
                }

                button {
                    background-color: $red-dark;
                    border: none;
                    outline: none !important;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color .3s ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: #8f1d25;
                    }
                }

                a {
                    background-color: $primary-color-font;
                    text-decoration: none;
                    border-radius: 8px;
                    padding: 14px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $secondary-color-font;
                    transition: background-color .3s ease-in-out;

                    &:hover {
                        background-color: $sky-blue;
                    }

                    &:last-of-type {
                        background-color: #FFFFFF;
                        color: $primary-color-font;
                        border: 1px solid $blue-dark;

                        &:hover {
                            background-color: $blue-dark;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            &:nth-of-type(even) {
                background-color: #f7f7f7;
            }
        }
    }
}